import { PricesService } from './prices.service';
import { ScrollingService } from './header/scrolling.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  loaded = false;

  constructor(private scroll: ScrollingService, 
    private pricesService: PricesService) { }

  ngOnInit(): void {
    this.loaded = this.pricesService.statusas;
    this.pricesService.uzkrove.subscribe(result => {
      this.loaded = true;
    });
  }


  scrollInto() {
    this.scroll.scrollInto('prices')
  }

}
