import { PricesService } from './../home/prices.service';
import { Component, ElementRef, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Price } from 'src/contracts/Price';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-prices-calculator',
  templateUrl: './prices-calculator.component.html',
  styleUrls: ['./prices-calculator.component.css']  
})
export class PricesCalculatorComponent implements OnInit {

  kainos: Price[] = [];
  selectedValue: string = "-1";
  ivestaDregme: number = 14.0;
  ivestasSiukslingumas: number = 1.0;
  rodytiSkaiciuokle = false;

  constructor(private pricesService: PricesService) { }

  ngOnInit(): void {
    this.pricesService.kainosSubject
    .subscribe(kainos => {
      this.kainos = kainos
      .filter(kaina => +kaina.Kaina);    
    });  
  }  
  

  rodytiSkaiciuotuva() {
    this.rodytiSkaiciuokle = true;
  }

  gautiParinktaKaina() {
    return (+this.kainos[+this.selectedValue].Kaina).toFixed(2);
  }

  gautiVirsitaDregme() {
    const virsyta = this.ivestaDregme - 14;
    if(virsyta >= 0) return virsyta.toFixed(2);
    else return 0;
  }
  gautiVirsitasSiukslingumas() {
    const virsyta = this.ivestasSiukslingumas - 1;
    if(virsyta >= 0) return virsyta.toFixed(2);
    else return 0;
  }

  gautiDziovinimoKaina() {
    return (1 * +this.gautiVirsitaDregme() * 3.5).toFixed(2);
  }

  gautiValymoKaina() {
    return (1 * +this.gautiVirsitasSiukslingumas() * 2).toFixed(2);
  }

  tonazasUzDregme() {
    return (1 - (100 - +this.gautiVirsitaDregme()) / 100).toFixed(2);
  }
  tonazasUzSiukslinguma() {
    return (1 - (100 - +this.gautiVirsitasSiukslingumas()) / 100).toFixed(2);
  }

  gautiLikusiTonaza() {
    return (1 - +this.tonazasUzDregme() - +this.tonazasUzSiukslinguma()).toFixed(2)
  }
  gautiLikusiaKaina() {
    return (+this.gautiLikusiTonaza()  * (+this.kainos[+this.selectedValue].Kaina) - +this.gautiValymoKaina() - +this.gautiDziovinimoKaina()).toFixed(2);
  }

}
