import { Price } from './../../../contracts/Price';
import { PricesService } from './../prices.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-prices-table',
  templateUrl: './prices-table.component.html',
  styleUrls: ['./prices-table.component.css',
   './css/main.css', './css/util.css'],
   animations: [
     trigger('services', [
       state('products', style({
         'opacity': '0'
       })),
       state('services', style({
         'opacity': '1'
       })),
       transition('products <=> services',
       animate(150)
       )
     ]),
     trigger('products', [
      state('products', style({
        'opacity': '1'
      })),
      state('services', style({
        'opacity': '0'
      })),
      transition('products <=> services',
      animate(150)
      )
    ])
   ]
})
export class PricesTableComponent implements OnInit {

  kainos: Price[] = [];
  skirtumas: number = 0;
  rodytiPaslaugas = true;
  state = "products"
  
  constructor(private pricesService: PricesService) { }

  ngOnInit(): void {
    this.pricesService.kainosSubject
    .subscribe(kainos => {
      this.kainos = kainos;
    })

    this.pricesService.skirtumasSubject
    .subscribe(skirtumas => {
      this.skirtumas = skirtumas;      
    })

    this.pricesService.gautiKainas();
    
  }

  gauti30dKaina(kaina: Price) {
    let galutineKaina: number;
    
    galutineKaina = +kaina.Kaina + this.skirtumas;
    
    if(galutineKaina) {
      return galutineKaina;
    }
    
    return kaina.Kaina;
  }
  keistiTipa(value: boolean) {
    value === true ? this.state = 'products' : this.state = 'services';
    setTimeout(() => {
      this.rodytiPaslaugas = value;
    }, 150);
    
  }

}
