<div id="footer">
    <div class="container">

        <div id="content">
            <div id="kontaktai">
                <h3 id="headerText" style="text-align: center; color: white;">KONTAKTAI</h3>
                <div class="contact">
                    <div class="contactLogo call"></div>
                    <a href="tel: +370 (680) 56 748">+370 (680) 56 748</a>
                </div>
                <div class="contact">
                    <div class="contactLogo email"></div>
                    <a href="mailto: info@kagro.lt">info@kagro.lt</a>
                </div>
            </div>
            <div id="partneriai">
                <h3 id="headerText" style="text-align: center; color: white;">MŪSŲ PARTNERIAI</h3>
                <div class="partneris">

                </div>
            </div>
        </div>
    </div>
</div>
