<hr>
<div class="container" style="padding-top: 30px;">
    <h4 id="headingText" style="text-align: center;">APIE MUS</h4>

    <p>MB „Kagro“ – įmonių grupė, siekianti palengvinti žemdirbių darbą, nuolat kurianti nuolatinę vertę bei savo klientams siūlanti tik aukščiausios kokybės produktus bei paslaugas. Efektyvus bei greitas žemės ūkio kultūrų realizavimas ir transportavimas darbymečio metu.</p>
    <li>Sklandaus kūlimo užtikrinimas.</li>
    <li>Bendradarbiavimas su sėkliniais ūkiais bei C2 kviečių sėklos pristatymas nemokamai.</li>
    <li>Tos pačios dienos atsiskaitymas bei mokamas avansas. </li>
    <li>Pervežimo paslaugos.</li>
	<li>Įvairių frakcijų: skaldos, smėlio, žvyro, juodžemio bei atsijų pardavimas ir pristatymas visoje Lietuvoje.</li>
</div>
<hr>