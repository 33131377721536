import { Price } from './../../contracts/Price';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  kainos: Price[] = [];
  statusas: boolean = false;
  kainosSubject = new Subject<Price[]>();
  uzkrove = new Subject<boolean>();
  skirtumasSubject = new BehaviorSubject<number>(20);

  constructor(private http: HttpClient) { }

  gautiKainas() {
    if(this.kainos.length === 0) {
      this.http.get<any>('https://api.kagro.lt/api/product/getproducts')
      .subscribe(result => {
        this.statusas = true;
        this.uzkrove.next(this.statusas);
        let skirtumas = result.bonus;
        this.skirtumasSubject.next(skirtumas);

        this.mapPrices(result.data);
        this.kainosSubject.next(this.kainos);
      });
    }
    else {
      this.kainosSubject.next(this.kainos);
    }
  }

  private mapPrices(result: any[])
  {
    this.kainos = [];

    result.forEach(row => {
      var price: Price =
      {
        Kaina: row.price,
        Tipas: row.name,
        Atnaujinama: +row.price ? true : false
      }

      this.kainos.push(price);
    });
  }
}
