
<app-header></app-header>
<div id="landingImage">
  <div id="landingText">
    <h3 id="landingHeader">GRUDŲ SUPIRKIMAS</h3>
    <p id="landingSecondText">Pinigai čia ir dabar!</p>
    <button class="readMoreButton" (click)="scrollInto()">Daugiau</button>
  </div>
  <div id="curve"></div>
</div>
<app-our-services></app-our-services>
<app-prices-table></app-prices-table>
<app-about-us></app-about-us>
<app-contacts></app-contacts>

<div *ngIf="!loaded" style="width: 100vw;
height: 100vh;
background-color: white;
position: absolute;
top: 0;">
  <div id="logo" style="height: 100px; position: absolute; width: 100%; top: 50%; transform: translateY(-50%);
  background-image: url(../../assets/img/logo.svg); background-repeat: no-repeat; background-size: contain; background-position-x: center;"></div>
</div>
