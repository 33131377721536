import { ScrollingService } from './scrolling.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private scroll: ScrollingService) { }
  yraZemiau = false;

  ngOnInit(): void {
  }

  fixedHeader(event: Event) {
    if(window.scrollY > 100) {
      this.yraZemiau = true;
    }
    else {
      this.yraZemiau = false;
    }
  }

  toggleMobileMenu() {
    let background = document.getElementById("mobileBackground");
    if(background?.style.display == "none") {
      background.style.display = "block";
      this.yraZemiau = true;
    }
    else if(background?.style.display == "block") {
      background.style.display = "none";
      this.yraZemiau = false;
    }
    document.getElementById("mobileMenu")?.classList.toggle('mobileMenuActive');
  }

  scrollInto(destination: String) {
    this.scroll.scrollInto(destination);
  }  
}
