
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="section-heading">
                    <h4 id="headingText">MŪSŲ PASLAUGOS</h4>
                    <div class="line-dec"></div>

                    <div class="servicesContainer">  
                        <div class="firstContainer">
                            <div class="service-item first-service">
                                <div class="icon"></div>
                                <h4 style="width: 98%;">GRŪDŲ SUPIRKIMAS
                                    </h4>                
                                    <p id="tekstas">Supirkimas visoje Lietuvoje.
                                        Išsivežame savo transportu. 
                                        Perkame nekondicinius grūdus.
                                    </p>            
                                    <p style="margin: 20px 0;"><b>Atsiskaitymas tą pačią dieną</b></p> 
                                    <div class="primary-button">
                                        <a 
                                        (click)="scrollInto('prices')"
                                        style="cursor: pointer;" 
                                        class="readMoreButton">Kaina</a>
                                    </div>                
                            </div>                   
                              
                            <div class="service-item second-service">
                                <div class="icon"></div>
                                <h4 style="width: 98%;">GRŪDŲ DŽIOVINIMO IR VALYMO PASLAUGOS
                                    </h4>                
                                    <p id="tekstas" style="padding-bottom: 30px;">Džiovinimo, valymo, beicavimo bei 
                                        taravimo paslaugos sėklos paruošimui
                                        su abiejų pusių pristatymu 
                                        jūsų patogumui.
                                    </p>            
                                    <div class="primary-button" style="margin-top: 10px;">
                                        <a 
                                        (click)="scrollInto('contacts')"
                                        style="cursor: pointer;"
                                        class="readMoreButton"
                                        >Susisiekti</a>
                                    </div>                
                            </div>   
                        </div>                        
                          
                        <div class="firstContainer">
                            <div class="service-item third-service">
                                <div class="icon"></div>
                                <h4 style="width: 98%;">PARDAVIMAS</h4>                
                                    <p id="tekstas">Įvairių frakcijų, smėlio, žvyro, 
                                        skaldos, atsijų pardavimas ir 
                                        pristatymas visoje Lietuvoje!
                                    </p>            
                                    <div class="primary-button" style="margin-top: 10px;">
                                        <a 
                                        (click)="scrollInto('contacts')"
                                        style="cursor: pointer;"
                                        class="readMoreButton"
                                        >Susisiekti</a>
                                    </div>                
                            </div>   
    
                            <div class="service-item forth-service">
                                <div class="icon"></div>
                                <h4 style="width: 98%;">LOGISTIKA</h4>                
                                    <p id="tekstas">Įvairių krovinių pervežimo 
                                        paslaugos visoje Lietuvoje!
                                    </p>            
                                    <div class="primary-button" style="margin-top: 10px;">
                                        <a 
                                        (click)="scrollInto('contacts')"
                                        style="cursor: pointer;"
                                        class="readMoreButton"
                                        >Susisiekti</a>
                                    </div>                
                            </div>  
                        </div>                                              
                    </div>
                </div>
            </div>
        </div>
    </div>
    
     