<div class="calcContainer">
    <div style="width: 100%; background-color:rgb(248, 248, 248);">
        <div id="calcHeader"
        [ngClass]="{'activeHeader': rodytiSkaiciuokle}"
        style="cursor: pointer;"
        (click)="rodytiSkaiciuotuva()">
            <h3 id="headingText">KAINŲ SKAIČIUOKLĖ</h3>  
            <div class="line-dec" style="background-color: white;"></div>  
        </div>        
        <div 
        *ngIf="rodytiSkaiciuokle"
        id="calcContent"         
        >
            <div class="calcRow">
                <div class="box">
                    <select 
                    required
                    [(ngModel)]="selectedValue">
                      <option 
                      disabled
                      selected="selectedValue"
                      value="-1">Pasirinkite rūšį</option>
                      <option                       
                      *ngFor="let kaina of kainos; let i = index" 
                      [value]="i"
                      >{{kaina.Tipas}}</option>                      
                    </select>
                  </div>
            </div>
            <div id="calcOptions">
                <div class="form-group" class="option">
                    <label for="dregme">Įveskite drėmę, %:</label>
                    <input 
                    required
                    [(ngModel)]="ivestaDregme"
                    type="text" 
                    class="form-control" 
                    id="dregme"
                    type="number">
                </div>
                <div class="form-group" class="option">
                    <label for="siuksl">Įveskite šiukšlingumą, %:</label>
                    <input type="text" 
                    required
                    [(ngModel)]="ivestasSiukslingumas"
                    class="form-control" 
                    id="siuksl"
                    type="number">
                </div>                                
            </div>
            <div class="line-dec" style="background-color: rgb(105, 105, 105);"></div>
            
            <div 
            class="table" 
            *ngIf="selectedValue !== '-1'"
            id="calcTable">
                <tr>
                    <td>Pradinė tonos kaina:</td>   
                    <td 
                    style="width: 80px;"
                    class="price">{{ gautiParinktaKaina() }} € / t</td>                 
                </tr>
                <tr>
                    <td>Viršyta bazinė dregmė</td>     
                    <td class="price">{{ gautiVirsitaDregme() }} %</td>               
                </tr>
                <tr>
                    <td>Viršytas bazinis šiukšlingumas</td>   
                    <td class="price">{{ gautiVirsitasSiukslingumas() }} %</td>                 
                </tr>
                <tr>
                    <td>Paslaugos už džiovinimą</td> 
                    <td class="price">- {{ gautiDziovinimoKaina() }} € / t</td>                   
                </tr>
                <tr>
                    <td>Paslaugos už valymą</td>             
                    <td class="price">- {{ gautiValymoKaina() }} € / t</td>       
                </tr>
                <tr>
                    <td>Išskaičiuojamas tonažas už dregmę</td>          
                    <td class="price">- {{ tonazasUzDregme() }} / t</td>          
                </tr>
                <tr>
                    <td>Išskaičiuojamas tonažas už šiukšlingumą</td> 
                    <td class="price">- {{ tonazasUzSiukslinguma() }} / t</td>                   
                </tr>
                <tr>
                    <td>Likęs tonažas</td> 
                    <td class="price">{{ gautiLikusiTonaza() }} t</td>                   
                </tr>
                <div style="width: 100%; display: flex; font-weight: bold;
                font-family: sans-serif;
                font-size: 20px;
                justify-content: space-between;">
                    <p>Galutinė kaina</p>
                    <p style="float: right;">{{ gautiLikusiaKaina() }} € / t</p>
                </div>     
            </div>
                            
        </div>
    </div>
</div>
