<div class="header" 
(window:scroll)="fixedHeader($event)"
[ngClass]="{'activeHeader': yraZemiau}">
    <div class="container">
        <nav class="navbar navbar-inverse" role="navigation">
            <div class="navbar-header">
                <button type="button" 
                id="nav-toggle" 
                class="navbar-toggle"
                (click)="toggleMobileMenu()">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a class="navbar-brand scroll-top" >
                    <div 
                    style="cursor: pointer;"
                    (click)="scrollInto('')" 
                    class="logo" 
                    [ngClass]="{'activeLogo': yraZemiau}"></div>
                </a>
            </div>
            <!--/.navbar-header-->
            <div id="main-nav" class="collapse navbar-collapse">
                <ul class="nav navbar-nav">                      
                    <li (click)="scrollInto('products')"><a [ngClass]="{'fixedHeaderText': yraZemiau}">PASLAUGOS</a></li>
                    <li (click)="scrollInto('prices')"><a [ngClass]="{'fixedHeaderText': yraZemiau}">GRUDŲ KAINOS</a></li>
                    <li (click)="scrollInto('about')"><a [ngClass]="{'fixedHeaderText': yraZemiau}">APIE MUS</a></li>
                    <li (click)="scrollInto('contacts')"><a [ngClass]="{'fixedHeaderText': yraZemiau}">KONTAKTAI</a></li>                    
                </ul>
            </div>
            <!--/.navbar-collapse-->
        </nav>
        <!--/.navbar-->
    </div>
    <!--/.container-->
</div>
<!--/.header-->

<!-- Mobile menu -->
<div 
#mobileBackground
id="mobileBackground" 
(click)="toggleMobileMenu()"
style="display: none;">
    <div id="mobileMenu">
        <div id="mobileMenuContent">
            <p (click)="scrollInto('products')">PASLAUGOS</p>
            <p (click)="scrollInto('prices')">GRŪDŲ KAINOS</p>
            <p (click)="scrollInto('about')">APIE MUS</p>
            <p (click)="scrollInto('contacts')">KONTAKTAI</p>

            <button type="button" class="btn" style="width: 90%; margin: auto; height: 55px; font-weight: bold; background-color: transparent; border: 2px solid #849B3B; color: #849B3B" onclick="window.location.href = 'tel:+37068056748';">
                SKAMBINTI
            </button>
        </div>
    </div>
</div>
