import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {

  constructor() { }

  scrollInto(destination: String) {
    switch (destination) {
      case "products":
        document.getElementsByTagName("app-our-services")[0]
        .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
      break;

      case "prices":
        document.getElementsByTagName("app-prices-table")[0]
        .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
      break;

      case "about":
        document.getElementsByTagName("app-about-us")[0]
        .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
      break
      case "contacts":
        document.getElementsByTagName("app-contacts")[0]
        .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
      break    
      default:
        document.getElementsByTagName("app-home")[0]
        .scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"}); 
        break;
    }    
  }
}
