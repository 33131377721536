<div class="container">
    <h4 id="headingText">KAINOS</h4>
    <div class="line-dec"></div>

    <div id="menuContainer">
        <div class="menuItem" (click)="keistiTipa(true)">PRODUKTAI</div>
        <div class="menuItem" (click)="keistiTipa(false)">PASLAUGOS</div>
    </div>

    <div class="table100"
    [@products]="state"
    *ngIf="rodytiPaslaugas"
    >
        <table>
            <thead>
                <tr class="table100-head">
                    <th class="column1"></th>
                    <th class="column2">TĄ PAČIĄ DIENĄ</th>
                    <th class="column2">IKI 30 DIENŲ</th>
                </tr>
            </thead>
            <tbody>
                    <tr *ngFor="let kaina of kainos">
                        <td class="column1">{{kaina.Tipas}}</td>
                        <td class="column2">{{kaina.Kaina}} €</td>
                        <td class="column2">{{gauti30dKaina(kaina)}} €</td>
                    </tr>                                                            
            </tbody>
        </table>
    </div>

    <div class="table100"
    *ngIf="!rodytiPaslaugas">
        <table [@services]="state">
            <thead>
                <tr class="table100-head">
                    <th class="column1"></th>
                    <th class="column2">Kaina</th>
                </tr>
            </thead>
            <tbody>
                    <tr>
                        <td class="column1">Džiovinimas už 1% / t</td>
                        <td class="column2">3,5€</td>
                    </tr>
                    <tr>
                        <td class="column1">Valymas už 1% / t</td>
                        <td class="column2">2,0 €</td>
                    </tr>
                    <tr>
                        <td class="column1">Beicavimas</td>
                        <td class="column2">20€ + beicas / 1t</td>
                    </tr>
                    <tr>
                        <td class="column1">Taravimas į maišus (25kg - 1000kg)</td>
                        <td class="column2">15€ - 100€ / t</td>
                    </tr>
                    <tr>
                        <td class="column1">Pakrovimas / Iškrovimas</td>
                        <td class="column2">NEMOKAMAI</td>
                    </tr>                                                              
            </tbody>
        </table>
    </div>
</div>

<app-prices-calculator></app-prices-calculator>